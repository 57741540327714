<template>
  <div class="pointer-events-none fixed inset-0 flex items-start justify-end p-6 px-4" style="z-index: 5000;">
    <div class="w-full max-w-lg">
      <TransitionGroup name="toasts">
        <div
          v-for="notification in toasts"
          :key="notification.id"
          class="notification pointer-events-auto mt-4 rounded-md p-4 shadow-lg"
          :class="notification.type === 'info' ? 'bg-green-50' : 'bg-red-50'"
          @click="removeToast(notification.id)"
        >
          <div class="flex">
            <div class="flex-shrink-0">
              <CheckCircleIcon v-if="notification.type === 'info'" class="h-3.5 w-3.5 fill-current text-green-400" aria-hidden="true" />
              <XCircleIcon v-else class="text-green-40 h-3.5 w-3.5 fill-current text-red-400" aria-hidden="true" />
            </div>
            <div class="ml-3">
              <div v-if="notification.title" class="title text-xs font-medium" :class="notification.type === 'info' ? 'text-green-800' : 'text-red-800'">
                {{ notification.title }}
              </div>
              <p v-if="notification.text" class="mt-2 text-xs" :class="notification.type === 'info' ? 'text-green-700' : 'text-red-700'">
                {{ notification.text }}
              </p>
            </div>
          </div>
        </div>
      </TransitionGroup>
    </div>
  </div>
</template>

<script>
import CheckCircleIcon from '@/components/icons/CheckCircleIcon.vue'
import XCircleIcon from '~~/components/icons/XCircleIcon.vue'
import useToast from '~/composables/useToast'

export default {
  components: { CheckCircleIcon, XCircleIcon },

  data: () => ({
    toasts: [],
    removeToast: null,
  }),

  mounted () {
    const { toasts, removeToast } = useToast()
    this.toasts = toasts
    this.removeToast = removeToast
  },
}
</script>

<style scoped>
.toasts-move,
.toasts-enter-active,
.toasts-leave-active {
  transition: all 0.5s ease-out;
}
.toasts-enter-from {
  opacity: 0;
  transform: translateX(30px);
}
.toasts-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
/* .toasts-leave-active {
  position: absolute;
} */
</style>
