<template>
  <form method="get" @submit.prevent="search">
    <InputGroup v-model="q" no-error-icon placeholder="Search" wrapper-class="py-2" class="w-full">
      <template #append>
        <button type="submit" class="flex items-center justify-center transition-colors hover:text-primary">
          <SearchIcon class="h-5 w-5" />
        </button>
      </template>
    </InputGroup>
  </form>
</template>

<script>
import InputGroup from '~/components/ui/InputGroup'
import SearchIcon from '~/components/icons/SearchIcon'

export default {
  components: { SearchIcon, InputGroup },

  data: () => ({
    data: '',
    q: '',
  }),

  watch: {
    '$route.query': function (val) {
      this.q = val.q
    },
  },

  mounted () {
    this.q = this.$route.query.q || ''
  },

  methods: {
    search () {
      this.$router.push({ path: '/boards', query: { q: this.q } })
    },
  },
}
</script>
