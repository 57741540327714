<template>
  <Dropdown v-model="open" align-right>
    <template #button>
      <button
        type="button"
        class="flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-primary"
        aria-expanded="false"
        aria-haspopup="true"
        @click.stop="toggleMenu"
      >
        <span class="sr-only">Open user menu</span>
        <img class="h-6 w-6 rounded-full md:h-8 md:w-8" :src="avatarUrl" alt="">
      </button>
    </template>

    <div
      class="absolute right-0 mt-0 w-48 origin-top-right rounded-md bg-white py-1 shadow-custom ring-black ring-opacity-5 focus:outline-none"
      role="menu"
      aria-orientation="vertical"
    >
      <!-- Active: "bg-gray-100", Not Active: "" -->
      <DropdownMenuItem>
        <NuxtLink :to="`/${user.username}`" class="flex items-center gap-2 py-1 text-sm text-gray-700" role="menuitem" tabindex="-1">
          <UserIcon class="h-4 w-4 text-gray-400" />
          My profile
        </NuxtLink>
      </DropdownMenuItem>
      <DropdownMenuItem>
        <NuxtLink to="/users/edit" class="flex items-center gap-2 py-1 text-sm text-gray-700" role="menuitem" tabindex="-1">
          <PencilIcon class="h-4 w-4 text-gray-400" />
          Edit Account
        </NuxtLink>
      </DropdownMenuItem>
      <DropdownMenuItem>
        <NuxtLink to="/users/bookmarks" class="flex items-center gap-2 py-1 text-sm text-gray-700" role="menuitem" tabindex="-1">
          <BookmarkIcon class="h-4 w-4 text-gray-400" />
          Bookmarks
        </NuxtLink>
      </DropdownMenuItem>
      <hr>
      <DropdownMenuItem>
        <NuxtLink to="/logout" class="flex w-full items-center gap-2 py-1 text-sm text-red-500" role="menuitem" tabindex="-1">
          <LogoutIcon class="h-4 w-4" />
          Sign out
        </NuxtLink>
      </DropdownMenuItem>
    </div>
  </dropdown>
</template>

<script>
import PencilIcon from '@/components/icons/PencilIcon'
import UserIcon from '@/components/icons/UserIcon'
import BookmarkIcon from '@/components/icons/BookmarkIcon'
import LogoutIcon from '@/components/icons/LogoutIcon'
import Dropdown from '@/components/ui/Dropdown'
import DropdownMenuItem from '@/components/ui/DropdownMenuItem'

export default {
  components: { UserIcon, PencilIcon, BookmarkIcon, LogoutIcon, Dropdown, DropdownMenuItem },

  props: {
    user: { type: Object, required: true },
  },

  data: () => ({
    open: false,
  }),

  computed: {
    avatarUrl () {
      return this.user.avatar ? this.user.avatar.thumb : `${this.$config.public.publicUrl}/images/user/avatar/thumb/missing.jpg`
    },
  },

  methods: {
    toggleMenu () {
      this.open = ! this.open
    },

    hideMenu () {
      this.open = false
    },
  },
}
</script>
