<template>
  <Dropdown v-model="open" class="relative text-sm" class-prop="rounded-md" align-right>
    <template #button>
      <button
        type="button"
        :class="{
          'text-primary': notificationsCount,
          'text-gray-400': !notificationsCount,
        }"
        class="rounded-full p-1 hover:text-primary focus:text-primary focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-primary"
        @click.stop="toggleMenu"
      >
        <span class="sr-only">View notifications</span>
        <BellIcon class="inline h-6 w-6" />
        <span class="text-xs" v-text="notificationsCount ? notificationsCount : ''" />
      </button>
    </template>

    <div class="relative w-72 rounded-md bg-white shadow-lg sm:w-96">
      <!-- title -->
      <div class="px-4 py-2 text-base font-medium md:text-lg">
        Unread Notifications
      </div>

      <!-- List of notifications -->
      <div class="flex max-h-[600px] flex-col gap-2 overflow-y-auto p-4">
        <NotificationItem v-for="notification in notifications.data" :key="notification.id" :notification="notification" @on-read="onRead" />

        <div v-if="!notificationsCount">
          Welcome to Project Credo. No new activity yet!
        </div>
      </div>

      <!-- actions -->
      <div class="sticky bottom-0 left-0 bg-white py-2">
        <div class="flex items-center justify-between px-4 text-sm">
          <NuxtLink to="/notifications" class="text-primary">
            See all
          </NuxtLink>
          <a v-if="notificationsCount" class="text-primary" role="button" @click="readAll">Read all</a>
        </div>
      </div>
    </div>
  </Dropdown>
</template>

<script>
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { mapState } from 'pinia'
import NotificationItem from './NotificationItem'
import Dropdown from '~/components/ui/Dropdown'
import BellIcon from '~/components/icons/BellIcon'
import { useAuthStore } from '~/stores/auth'
import { useApi } from '~/composables/useApi'

dayjs.extend(relativeTime)

export default {
  components: { NotificationItem, BellIcon, Dropdown },

  data: () => ({
    notifications: {
      data: [],
    },

    open: false,
  }),

  computed: {
    notificationsCount () {
      return this.notifications.data.length
    },

    ...mapState(useAuthStore, ['user']),
  },

  mounted () {
    if (this.user) {
      this.loadNotifications()
    }
  },

  methods: {
    async loadNotifications () {
      this.notifications = await useApi('/api/notifications')
    },

    onRead (notification) {
      notification.read_at = dayjs().toISOString()
    },

    async readAll () {
      await useApi('/api/notifications/read-all', { method: 'post' })
      this.notifications.data = []
    },

    toggleMenu () {
      this.open = ! this.open
    },

    hideMenu () {
      this.open = false
    },
  },
}
</script>
