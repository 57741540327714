<template>
  <div class="bg-white font-montserrat text-sm">
    <Header />
    <slot />
    <Footer />
    <ClientOnly>
      <Notifications />
    </ClientOnly>
  </div>
</template>

<script>
import Header from '@/components/layouts/Header'
import Footer from '@/components/layouts/Footer'
import Notifications from '~/components/layouts/Notifications'

export default {
  // eslint-disable-next-line vue/no-reserved-component-names
  components: { Header, Footer, Notifications },
}
</script>
