<template>
  <header>
    <div class="main-header w-full lg:relative lg:w-auto">
      <!-- desktop menu -->
      <Container>
        <div class="hidden items-center justify-between py-8 lg:flex">
          <div>
            <NuxtLink to="/" class="logo-head">
              <img class="h-[70px] w-[70px]" src="~assets/images/logo.png" alt="logo">
            </NuxtLink>
          </div>
          <div class="flex items-center gap-5">
            <nav class="menu text-md flex h-full items-center justify-end gap-7 font-medium capitalize">
              <NuxtLink to="/" class="hover:text-primary">
                Home
              </NuxtLink>
              <NuxtLink to="/about" class="hover:text-primary">
                About
              </NuxtLink>
              <a href="https://www.buzzsprout.com/1673452" target="_blank" class="hover:text-primary">Fansplaining Podcast</a>
              <a href="https://www.patreon.com/projectcredo" target="_blank" class="hover:text-primary">Sponsor us</a>
            </nav>
          </div>

          <!-- Sign-in button or user profile -->
          <div class="flex items-center gap-4">
            <QuickSearch />
            <!-- guest -->
            <NuxtLink v-if="!user" to="/users/sign-in">
              <button type="button" class="rounded bg-primary px-10 py-3 text-sm text-white">
                Sign in
              </button>
            </NuxtLink>
            <!-- authenticated user -->
            <div v-else class="flex gap-3">
              <Notifications />
              <UserMenu :user="user" />
            </div>
          </div>
        </div>
      </Container>

      <!-- mobile menu -->
      <Container>
        <div class="mobile-menu relative flex items-center justify-between py-3 sm:px-0 lg:hidden">
          <div>
            <NuxtLink to="/" class="logo-head">
              <img class="h-[60px] w-[60px]" src="~assets/images/logo.png" alt="logo">
            </NuxtLink>
          </div>
          <QuickSearch class="flex-1 px-3 sm:px-10" />
          <div class="flex items-center gap-2">
            <Notifications />
            <UserMenu v-if="user" :user="user" />
            <Dropdown v-model="showMenu" align-right>
              <template #button>
                <button class="flex items-center text-white" type="button" @click="showMenu = !showMenu">
                  <MenuIcon v-show="!showMenu" class="h-7 w-7 text-gray-400" />
                  <XIcon v-show="showMenu" class="h-7 w-7 text-gray-400" />
                </button>
              </template>

              <div class="top-full w-64 list-none divide-y divide-gray-100 rounded bg-white text-base shadow">
                <NuxtLink to="/about" class="block px-4 py-2 text-sm text-gray-700" @click="showMenu = false">
                  About us
                </NuxtLink>
                <a href="https://www.buzzsprout.com/1673452" target="_blank" class="block px-4 py-2 text-sm text-gray-700">
                  Fansplaining Podcast
                </a>
                <a href="https://www.patreon.com/projectcredo" target="_blank" class="block px-4 py-2 text-sm text-gray-700">
                  Sponsor us
                </a>
                <NuxtLink v-if="!user" to="/users/sign-in" class="block px-4 py-2 text-sm text-gray-700" @click="showMenu = false">
                  Signup / Signin
                </NuxtLink>
              </div>
            </Dropdown>
          </div>
        </div>
      </Container>
    </div>
  </header>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import MenuIcon from '@/components/icons/MenuIcon'
import Container from '@/components/layouts/Container'
import UserMenu from '~/components/navbar/UserMenu'
import Notifications from '~/components/navbar/Notifications'
import Dropdown from '~/components/ui/Dropdown'
import XIcon from '~/components/icons/XIcon'
import QuickSearch from '~/components/layouts/QuickSearch'
import { useAuthStore } from '~/stores/auth'

export default {
  components: { QuickSearch, XIcon, MenuIcon, Container, UserMenu, Notifications, Dropdown },

  data: () => ({
    showMenu: false,
  }),

  computed: {
    ...mapState(useAuthStore, ['user']),
  },

  methods: {
    ...mapActions(useAuthStore, ['signOut']),
  },
}
</script>
