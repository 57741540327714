<template>
  <footer>
    <div class="bg-primary-light">
      <Container class="flex flex-col gap-y-8 px-4 py-10 md:flex-row md:justify-between">
        <!-- main info -->
        <div class="flex flex-col gap-5">
          <img class="h-14 w-14" src="~assets/images/logo.png" alt="logo">
          <h2 class="font-oswald text-3xl font-medium uppercase tracking-wide text-primary">
            Project Credo
          </h2>
        </div>

        <!-- quick links -->
        <div>
          <h3 class="font-oswald text-3xl font-semibold uppercase">
            Quick links
          </h3>
          <ul class="mt-5 flex flex-col gap-1 font-medium">
            <li>
              <a href="https://www.patreon.com/projectcredo/creators" title="support us on Patreon" target="_blank" class="hover:text-primary">Patreon</a>
            </li>
            <li>
              <NuxtLink to="/how-to" class="hover:text-primary">
                Community Guidelines
              </NuxtLink>
            </li>
            <li>
              <a href="#" class="hover:text-primary">Terms of Use</a>
            </li>
          </ul>
        </div>

        <div>
          <h3 class="font-oswald text-3xl font-semibold uppercase">
            Contact us
          </h3>
          <div class="mt-5 flex flex-col gap-2">
            <div class="flex items-center gap-3">
              <MailIcon class="h-6 w-6 text-primary-dark" /> <a href="mailto:hello@projectcredo.com" class="hover:text-primary">hello@projectcredo.com</a>
            </div>
            <div class="flex items-center gap-3">
              <a href="https://www.facebook.com/ProjectCredo" target="_blank" title="follow project credo on facebook" class="-ml-1 hover:text-primary">
                <FaceBookIcon class="h-6 w-6" />
              </a>
              <a href="https://www.instagram.com/projectcredo" target="_blank" title="follow project credo on instagram" class="hover:text-primary">
                <InstagramIcon class="h-5 w-5" />
              </a>
            </div>
          </div>
        </div>
      </Container>
    </div>

    <!-- copy right -->
    <div>
      <Container class="py-4">
        <p class="text-center font-medium">
          &copy; Copyright 2022 <a href="#" class="inline-block text-primary">Projectcredo</a>
        </p>
      </Container>
    </div>
  </footer>
</template>

<script>
import MailIcon from '@/components/icons/MailIcon'
import FaceBookIcon from '@/components/icons/FaceBookIcon'
import InstagramIcon from '@/components/icons/InstagramIcon'
import Container from '@/components/layouts/Container'

export default {
  components: { Container, MailIcon, FaceBookIcon, InstagramIcon },
}
</script>
